import React from "react";
import BASEvent from '@/components/BASEvent'
const BASEventPage = ({ children }) => {
  return (
    <div className="pageBASEvent">
      <BASEvent/>
    </div>
  );
};
export default BASEventPage;
