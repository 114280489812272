import React, { useMemo } from "react";
import Slider from "react-slick";
import useBreakPoint from "@/hooks/useBreakPoint";
import useWinWidth from "@/hooks/useWinWidth";
import "./index.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const descList = [
  {
    id: "10",
    link: "https://medium.com/@padolabs/pado-new-partnership-with-brevis-4176cc91e260",
    date: "15 Dec 2023",
    type: "# News",
    title: "PADO new partnership with Brevis",
    desc: "PADO is delighted to announce our new partnership with Brevis!",
  },
  {
    id: "9",
    link: "https://medium.com/@padolabs/pado-new-partnership-with-zkusd-8247116e37a9",
    date: "8 Dec 2023",
    type: "# News",
    title: "PADO new partnership with ZKT Network",
    desc: "PADO is delighted to announce our new partnership with ZKT Network!",
  },
  {
    id: "8",
    link: "https://mailchi.mp/berkeley/blockchain_xcelerator-announcing-our-2024-xcelerator-cohort-teams",
    date: "7 Dec 2023",
    type: "# News",
    title: "PADO selected to the cohort7 of Berkeley Blockchain Xcelerator",
    desc: "The Berkeley Blockchain Xcelerator is proud to announce its next cohort for 2024 - cohort 7!",
  },
  {
    id: "7",
    link: "https://consensys.io/blog/introducing-the-first-cohort-of-the-consensys-fellowship-program-for-web3",
    date: "7 Nov 2023",
    type: "# News",
    title: "PADO accepted to the first cohort of ConsenSys Fellowship",
    desc: "16 impressive startups are set to make waves in Ethereum and web3 in ConsenSys Fellowship. ",
  },
  {
    id: "6",
    link: "https://medium.com/@padolabs/pado-team-excitedly-announces-upcoming-beta-product-launch-and-reveals-3million-seed-funding-f86627c21dbf",
    date: "18 Aug 2023",
    type: "# News",
    title: "PADO Labs Launches Beta Product",
    desc: "PADO Team Excitedly Announces Upcoming Beta Product Launch and Reveals $3Million Seed Funding",
  },
  {
    id: "5",
    link: "https://twitter.com/BNBCHAIN/status/1682015301259390977",
    date: "19 Jul 2023",
    type: "# News",
    title: (
      <>
        Won the 3<sup>rd</sup> prize in the BNB Chain Zero2Hero Pitch Day
      </>
    ),
    desc: "PADO Labs won the 3rd prize in the BNB Chain Zero2Hero incubator!",
  },
  {
    id: "4",
    link: "https://twitter.com/Cointelegraph/status/1681637494356422659",
    date: "19 Jul 2023",
    type: "# News",
    title: (
      <>
        Won the 2<sup>nd</sup> prize at zkDay Paris
      </>
    ),
    desc: "PADO Labs gave a pitch talk to explain (VOLE-based) IZK protocols and connections with snarks.",
  },
  {
    id: "3",
    link: "https://twitter.com/padolabs/status/1681489770030198784",
    date: "19 Jul 2023",
    type: "# News",
    title: "New Partnership with ZAN",
    desc: "PADO is now collaborating with ZAN to provide a lightweight eKYC service based on the Proof of Verification (POV) solution.",
  },
  {
    id: "2",
    link: "https://medium.com/@padolabs/how-to-build-a-web3-credit-ecosystem-with-off-chain-data-validity-cf8c67e5aa05",
    date: "5 Jul 2023",
    type: "# Blog",
    title: "How to Build a Web3 Credit Ecosystem with Off-chain ...",
    desc: "With the continual advancement of public blockchains, Web3 applications have progressed to a new stage. These include games, quest systems, content",
  },
  {
    id: "1",
    link: "https://twitter.com/BNBCHAIN/status/1669014722132180993",
    date: "15 Jun 2023",
    type: "# News",
    title: "Selected to participate in BNB chain Zero2Hero Incubator",
    desc: "This five-week program provides resources, mentorship, and networking opportunities to take these exciting projects to the next level.",
  },
  {
    id: "0",
    link: "https://medium.com/@padolabs/zkdata-attestation-proves-everything-with-cryptography-d435e68f66dd",
    date: "6 Mar 2023",
    type: "# Blog",
    title: "zkData Attestation, Proves Everything with Cryptography",
    desc: "Recently, the Ethereum developer conference ETHDenver was in full swing, and a somewhat unfamiliar term “Attestation” was mentioned by many developers.",
  },

  {
    id: "11",
    link: "https://eprint.iacr.org/2023/964",
    date: "2 Feb 2024",
    type: "# News",
    title: (
      <>
        PADO research team submission accepted by 33<sup>rd</sup> USENIX
      </>
    ),
    desc: "Lightweight Authentication of Web Data via Garble-Then-Prove",
  },
];
const baseSettings = {
  className: "center",
  centerMode: true,
  infinite: true,
  centerPadding: "60px",
  slidesToShow: 3.2,
  speed: 500,
  autoplay: true,
  pauseOnHover: true,
};
export default function CenterMode() {
  const breakPoint = useBreakPoint();
  const size = useWinWidth();

  const slidesToShow = useMemo(() => {
    const { width } = size;
    if (width < 576) {
      return 0.85;
    }
    if (width >= 993 && width < 1280) {
      return 2.3;
    }
    return 3.2;
  }, [size]);
  return (
    <div className={breakPoint === "s" ? "pSlick" : "pSlick pc"}>
      <Slider {...baseSettings} slidesToShow={slidesToShow}>
        {descList.map((i) => {
          return (
            <div className="mySlickItem" key={i.title}>
              <a href={i.link} target="_blank" rel="noreferrer">
                <div className="descT">
                  <div className="dateWrapper">
                    <span>{i.date}</span>
                    <i className="separator"></i>
                    <span>{i.type}</span>
                  </div>
                  <h6 className="title">{i.title}</h6>
                  <p className="desc">{i.desc}</p>
                </div>
                <div className="descC">Read More</div>
              </a>
            </div>
          );
        })}
      </Slider>
    </div>
  );
}
