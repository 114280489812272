const counties = [
  {
    abbr: "AF",
    chinese: "阿富汗",
    code: "93",
    text: "Afghanistan",
    spell: "afuhan",
    state: "亚洲",
    french: "Afghanistan",
    italian: "Afghanistan",
    spanish: "Afganistán",
    japanese: "アフガニスタン",
    russian: "Афганистан",
    germen: "Afghanistan",
  },
  {
    abbr: "AL",
    chinese: "阿尔巴尼亚",
    code: "355",
    text: "Albania",
    spell: "aerbaniya",
    state: "亚洲",
    french: "Albanie",
    italian: "Albania",
    spanish: "Albania",
    japanese: "アルバニア",
    russian: "Албания",
    germen: "Albanien",
  },
  {
    abbr: "DZ",
    chinese: "阿尔及利亚",
    code: "213",
    text: "Algeria",
    spell: "aerjiliya",
    state: "非洲",
    french: "Algérie",
    italian: "Algeria",
    spanish: "Argelia",
    japanese: "アルジェリア",
    russian: "Алжир",
    germen: "Algerien",
  },
  {
    abbr: "AO",
    chinese: "安哥拉",
    code: "244",
    text: "Angola",
    spell: "angela",
    state: "非洲",
    french: "Angola",
    italian: "Angola",
    spanish: "Angola",
    japanese: "アンゴラ",
    russian: "Ангола",
    germen: "Angola",
  },
  {
    abbr: "AR",
    chinese: "阿根廷",
    code: "54",
    text: "Argentina",
    spell: "agenting",
    state: "南美洲",
    french: "Argentine",
    italian: "Argentina",
    spanish: "Argentina",
    japanese: "アルゼンチン",
    russian: "Аргентина",
    germen: "Argentinien",
  },
  {
    abbr: "AM",
    chinese: "亚美尼亚",
    code: "374",
    text: "Armenia",
    spell: "yameiniya",
    french: "Arménie",
    italian: "Armenia",
    spanish: "Armenia",
    japanese: "アルメニア",
    russian: "Армения",
    germen: "Armenien",
  },
  {
    abbr: "AU",
    chinese: "澳大利亚",
    code: "61",
    text: "Australia",
    spell: "aodaliya",
    state: "大洋洲",
    french: "Îles Cocos",
    italian: "Isole Cocos e Keeling",
    spanish: "Islas Cocos o Islas Keeling",
    japanese: "ココス（キーリング）諸島",
    russian: "Австралия",
    germen: "Kokosinseln",
  },
  {
    abbr: "AT",
    chinese: "奥地利",
    code: "43",
    text: "Austria",
    spell: "aodili",
    state: "欧洲",
    french: "Autriche",
    italian: "Austria",
    spanish: "Austria",
    japanese: "オーストリア",
    russian: "Австрия",
    germen: "Österreich",
  },
  {
    abbr: "AZ",
    chinese: "阿塞拜疆",
    code: "994",
    text: "Azerbaijan",
    spell: "asaibaijiang",
    state: "亚洲",
    french: "Azerbaïdjan",
    italian: "Azerbaijan",
    spanish: "Azerbaiyán",
    japanese: "アゼルバイジャン",
    russian: "Азербайджан",
    germen: "Aserbaidschan",
  },
  {
    abbr: "BH",
    chinese: "巴林",
    code: "973",
    text: "Bahrain",
    spell: "balin",
    state: "亚洲",
    french: "Bahreïn",
    italian: "Bahrein",
    spanish: "Bahrein",
    japanese: "バーレーン",
    russian: "Бахрейн",
    germen: "Bahrain",
  },
  {
    abbr: "BD",
    chinese: "孟加拉国",
    code: "880",
    text: "Bangladesh",
    spell: "mengjialaguo",
    state: "亚洲",
    french: "Bangladesh",
    italian: "Bangladesh",
    spanish: "Bangladesh",
    japanese: "バングラデシュ",
    russian: "Бангладеш",
    germen: "Bangladesch",
  },
  {
    abbr: "BY",
    chinese: "白俄罗斯",
    code: "375",
    text: "Belarus",
    spell: "baieluosi",
    french: "Biélorussie",
    italian: "Bielorussia",
    spanish: "Bielorrusia",
    japanese: "ベラルーシ",
    russian: "Белоруссия",
    germen: "Weißrussland",
  },
  {
    abbr: "BE",
    chinese: "比利时",
    code: "32",
    text: "Belgium",
    spell: "bilishi",
    state: "欧洲",
    french: "Belgique",
    italian: "Belgio",
    spanish: "Bélgica",
    japanese: "ベルギー",
    russian: "Бельгия",
    germen: "Belgien",
  },
  {
    abbr: "BZ",
    chinese: "伯利兹",
    code: "501",
    text: "Belize",
    spell: "bolizi",
    french: "Belize",
    italian: "Belize",
    spanish: "Belice",
    japanese: "ベリーズ",
    russian: "Белиз",
    germen: "Belize",
  },
  {
    abbr: "BJ",
    chinese: "贝宁",
    code: "229",
    text: "Benin",
    spell: "beining",
    state: "非洲",
    french: "Bénin",
    italian: "Benin",
    spanish: "Benín",
    japanese: "ベナン",
    russian: "Бенин",
    germen: "Benin",
  },
  {
    abbr: "BT",
    chinese: "不丹",
    code: "975",
    text: "Bhutan",
    spell: "budan",
    state: "亚洲",
    french: "Bhoutan",
    italian: "Bhutan",
    spanish: "Bután",
    japanese: "ブータン",
    russian: "Бутан",
    germen: "Bhutan",
  },
  {
    abbr: "BO",
    chinese: "玻利维亚",
    code: "591",
    text: "Bolivia",
    spell: "boliweiya",
    state: "南美洲",
    french: "Bolivie",
    italian: "Bolivia",
    spanish: "Bolivia",
    japanese: "ボリビア多民族国",
    russian: "Боливия",
    germen: "Bolivien",
  },
  {
    abbr: "BA",
    chinese: "波斯尼亚和黑塞哥维那",
    code: "387",
    text: "Bosnia and Herzegovina",
    spell: "bosiniyaheheisaigeweinei",
    french: "Bosnie-Herzégovine",
    italian: "Bosnia ed Erzegovina",
    spanish: "Bosnia y Herzegovina",
    japanese: "ボスニア・ヘルツェゴビナ",
    russian: "Босния и Герцеговина",
    germen: "Bosnien und Herzegowina",
  },
  {
    abbr: "BW",
    chinese: "博茨瓦纳",
    code: "267",
    text: "Botswana",
    spell: "bociwana",
    state: "非洲",
    french: "Botswana",
    italian: "Botswana",
    spanish: "Botswana",
    japanese: "ボツワナ",
    russian: "Ботсвана",
    germen: "Botswana",
  },
  {
    abbr: "BR",
    chinese: "巴西",
    code: "55",
    text: "Brazil",
    spell: "baxi",
    state: "南美洲",
    french: "Brésil",
    italian: "Brasile",
    spanish: "Brasil",
    japanese: "ブラジル",
    russian: "Бразилия",
    germen: "Brasilien",
  },
  {
    abbr: "VG",
    chinese: "英属维京群岛",
    code: "1284",
    text: "British Virgin Islands",
    spell: "yingshuweijingqundao",
    french: "Îles Vierges britanniques",
    italian: "Isole Vergini Britanniche",
    spanish: "Islas Vírgenes del Reino Unido",
    japanese: "イギリス領ヴァージン諸島",
    russian: "Британские Виргинские острова",
    germen: "Britische Jungferninseln",
  },
  {
    abbr: "BN",
    chinese: "文莱",
    code: "673",
    text: "Brunei",
    spell: "wenlai",
    state: "亚洲",
    french: "Brunei",
    italian: "Brunei",
    spanish: "Brunei",
    japanese: "ブルネイ・ダルサラーム",
    russian: "Bulgaria",
    germen: "Brunei",
  },
  {
    abbr: "BG",
    chinese: "保加利亚",
    code: "359",
    text: "Bulgaria",
    spell: "baojialiya",
    state: "欧洲",
    french: "Bulgarie",
    italian: "Bulgaria",
    spanish: "Bulgaria",
    japanese: "ブルガリア",
    russian: "Болгария",
    germen: "Bulgarien",
  },
  {
    abbr: "BF",
    chinese: "布基纳法索",
    code: "226",
    text: "Burkina-faso",
    spell: "bujinafasuo",
    french: "Burkina Faso",
    italian: "Burkina Faso",
    spanish: "Burkina Faso",
    japanese: "ブルキナファソ",
    russian: "Буркина-Фасо",
    germen: "Burkina Faso",
  },
  {
    abbr: "BI",
    chinese: "布隆迪",
    code: "257",
    text: "Burundi",
    spell: "bulongdi",
    state: "非洲",
    french: "Burundi",
    italian: "Burundi",
    spanish: "Burundi",
    japanese: "ブルンジ",
    russian: "Бурунди",
    germen: "Burundi",
  },
  {
    abbr: "KH",
    chinese: "柬埔寨",
    code: "855",
    text: "Cambodia",
    spell: "jianpuzhai",
    state: "亚洲",
    french: "Cambodge",
    italian: "Cambogia",
    spanish: "Camboya",
    japanese: "カンボジア",
    russian: "Камбоджа",
    germen: "Kambodscha",
  },
  {
    abbr: "CM",
    chinese: "喀麦隆",
    code: "237",
    text: "Cameroon",
    spell: "kamailong",
    state: "非洲",
    french: "Cameroun",
    italian: "Camerun",
    spanish: "Camerún",
    japanese: "カメルーン",
    russian: "Камерун",
    germen: "Kamerun",
  },
  {
    abbr: "CA",
    chinese: "加拿大",
    code: "1",
    text: "Canada",
    spell: "jianada",
    state: "北美洲",
    french: "États-Unis",
    italian: "Stati Uniti D\u0027America",
    spanish: "Estados Unidos",
    japanese: "アメリカ合衆国",
    russian: "Канада",
    germen: "Vereinigte Staaten von Amerika",
  },
  {
    abbr: "CV",
    chinese: "佛得角",
    code: "238",
    text: "Cape Verde",
    spell: "fodejiao",
    state: "非洲",
    french: "Cap Vert",
    italian: "Capo Verde",
    spanish: "Cabo Verde",
    japanese: "カーボベルデ",
    russian: "Кабо-Верде",
    germen: "Kap Verde",
  },
  {
    abbr: "KY",
    chinese: "开曼群岛",
    code: "1345",
    text: "Cayman Islands",
    spell: "kaimanqundao",
    french: "Îles Caïmans",
    italian: "Isole Cayman",
    spanish: "Islas Caimán",
    japanese: "ケイマン諸島",
    russian: "Каймановы Острова",
    germen: "Kaimaninseln",
  },
  {
    abbr: "CF",
    chinese: "中非共和国",
    code: "236",
    text: "Central African Republic",
    spell: "zhongfeigongheguo",
    state: "非洲",
    french: "République centrafricaine",
    italian: "Repubblica Centrafricana",
    spanish: "República Centroafricana",
    japanese: "中央アフリカ共和国",
    russian: "Центрально-Африканская Республика",
    germen: "Zentralafrikanische Republik",
  },
  {
    abbr: "TD",
    chinese: "乍得",
    code: "235",
    text: "Chad",
    spell: "zhade",
    french: "Tchad",
    italian: "Ciad",
    spanish: "Chad",
    japanese: "チャド",
    russian: "Чад",
    germen: "Tschad",
  },
  {
    abbr: "CL",
    chinese: "智利",
    code: "56",
    text: "Chile",
    spell: "zhili",
    state: "南美洲",
    french: "Chili",
    italian: "Cile",
    spanish: "Chile",
    japanese: "チリ",
    russian: "Чили",
    germen: "Chile",
  },
  {
    abbr: "CN",
    chinese: "中国",
    code: "86",
    text: "China",
    spell: "zhongguo",
    state: "亚洲",
    french: "Chine",
    italian: "Cina",
    spanish: "China",
    japanese: "中国",
    russian: "Китай",
    germen: "China",
  },
  {
    abbr: "CO",
    chinese: "哥伦比亚",
    code: "57",
    text: "Colombia",
    spell: "gelunbiya",
    state: "南美洲",
    french: "Colombie",
    italian: "Colombia",
    spanish: "Colombia",
    japanese: "コロンビア",
    russian: "Колумбия",
    germen: "Kolumbien",
  },
  {
    abbr: "KM",
    chinese: "科摩罗",
    code: "269",
    text: "Comoros",
    spell: "kemoluo",
    french: "Comores",
    italian: "Comore",
    spanish: "Comoras",
    japanese: "コモロ",
    russian: "Коморские острова",
    germen: "Union der Komoren",
  },
  {
    abbr: "CG",
    chinese: "刚果(布)",
    code: "242",
    text: "Congo - Brazzaville",
    spell: "gangguo(bu)",
    state: "非洲",
    french: "Congo",
    italian: "Congo",
    spanish: "Congo",
    japanese: "コンゴ共和国",
    russian: "Конго - Браззавиль",
    germen: "Kongo",
  },
  {
    abbr: "CD",
    chinese: "刚果(金)",
    code: "243",
    text: "Congo - Kinshasa",
    spell: "gangguo(jin)",
    french: "Congo (Rép. dém.)",
    italian: "Congo (Rep. Dem.)",
    spanish: "Congo (Rep. Dem.)",
    japanese: "コンゴ民主共和国",
    russian: "Конго - Киншаса",
    germen: "Kongo (Dem. Rep.)",
  },
  {
    abbr: "CR",
    chinese: "哥斯达黎加",
    code: "506",
    text: "Costa Rica",
    spell: "gesidalijia",
    state: "北美洲",
    french: "Costa Rica",
    italian: "Costa Rica",
    spanish: "Costa Rica",
    japanese: "コスタリカ",
    russian: "Коста-Рика",
    germen: "Costa Rica",
  },
  {
    abbr: "HR",
    chinese: "克罗地亚",
    code: "385",
    text: "Croatia",
    spell: "keluodiya",
    state: "欧洲",
    french: "Croatie",
    italian: "Croazia",
    spanish: "Croacia",
    japanese: "クロアチア",
    russian: "Хорватия",
    germen: "Kroatien",
  },
  {
    abbr: "CY",
    chinese: "塞浦路斯",
    code: "357",
    text: "Cyprus",
    spell: "saipulusi",
    state: "亚洲",
    french: "Chypre",
    italian: "Cipro",
    spanish: "Chipre",
    japanese: "キプロス",
    russian: "Кипр",
    germen: "Zypern",
  },
  {
    abbr: "CZ",
    chinese: "捷克共和国",
    code: "420",
    text: "Czech Republic",
    spell: "jiekegongheguo",
    state: "欧洲",
    french: "République tchèque",
    italian: "Repubblica Ceca",
    spanish: "República Checa",
    japanese: "チェコ",
    russian: "Чехия",
    germen: "Tschechische Republik",
  },
  {
    abbr: "DK",
    chinese: "丹麦",
    code: "45",
    text: "Denmark",
    spell: "danmai",
    state: "欧洲",
    french: "Danemark",
    italian: "Danimarca",
    spanish: "Dinamarca",
    japanese: "デンマーク",
    russian: "Дания",
    germen: "Dänemark",
  },
  {
    abbr: "DJ",
    chinese: "吉布提",
    code: "253",
    text: "Djibouti",
    spell: "jibuti",
    french: "Djibouti",
    italian: "Gibuti",
    spanish: "Yibuti",
    japanese: "ジブチ",
    russian: "Джибути",
    germen: "Dschibuti",
  },
  {
    abbr: "DO",
    chinese: "多米尼加共和国",
    code: "1809",
    text: "Dominican Republic",
    spell: "duominijiagongheguo",
    french: "République dominicaine",
    italian: "Repubblica Dominicana",
    spanish: "República Dominicana",
    japanese: "ドミニカ共和国",
    russian: "Доминиканская Республика",
    germen: "Dominikanische Republik",
  },
  {
    abbr: "EC",
    chinese: "厄瓜多尔",
    code: "593",
    text: "Ecuador",
    spell: "eguaduoer",
    state: "南美洲",
    french: "Équateur",
    italian: "Ecuador",
    spanish: "Ecuador",
    japanese: "エクアドル",
    russian: "Эквадор",
    germen: "Ecuador",
  },
  {
    abbr: "EG",
    chinese: "埃及",
    code: "20",
    text: "Egypt",
    spell: "aiji",
    state: "非洲",
    french: "Égypte",
    italian: "Egitto",
    spanish: "Egipto",
    japanese: "エジプト",
    russian: "Египет",
    germen: "Ägypten",
  },
  {
    abbr: "SV",
    chinese: "萨尔瓦多",
    code: "503",
    text: "EI Salvador",
    spell: "saerwaduo",
    french: "Salvador",
    italian: "El Salvador",
    spanish: "El Salvador",
    japanese: "エルサルバドル",
    russian: "EI Сальвадор",
    germen: "El Salvador",
  },
  {
    abbr: "GQ",
    chinese: "赤道几内亚",
    code: "240",
    text: "Equatorial Guinea",
    spell: "chidaojineiya",
    state: "非洲",
    french: "Guinée-Équatoriale",
    italian: "Guinea Equatoriale",
    spanish: "Guinea Ecuatorial",
    japanese: "赤道ギニア",
    russian: "Экваториальная Гвинея",
    germen: "Äquatorial-Guinea",
  },
  {
    abbr: "ER",
    chinese: "厄立特里亚",
    code: "291",
    text: "Eritrea",
    spell: "eliteliya",
    french: "Érythrée",
    italian: "Eritrea",
    spanish: "Eritrea",
    japanese: "エリトリア",
    russian: "Эритрея",
    germen: "Eritrea",
  },
  {
    abbr: "EE",
    chinese: "爱沙尼亚",
    code: "372",
    text: "Estonia",
    spell: "aishaniya",
    state: "欧洲",
    french: "Estonie",
    italian: "Estonia",
    spanish: "Estonia",
    japanese: "エストニア",
    russian: "Эстония",
    germen: "Estland",
  },
  {
    abbr: "ET",
    chinese: "埃塞俄比亚",
    code: "251",
    text: "Ethiopia",
    spell: "aisaiebiya",
    state: "非洲",
    french: "Éthiopie",
    italian: "Etiopia",
    spanish: "Etiopía",
    japanese: "エチオピア",
    russian: "Эфиопия",
    germen: "Äthiopien",
  },
  {
    abbr: "FJ",
    chinese: "斐济",
    code: "679",
    text: "Fiji",
    spell: "feiji",
    state: "大洋洲",
    french: "Fidji",
    italian: "Figi",
    spanish: "Fiyi",
    japanese: "フィジー",
    russian: "Фиджи",
    germen: "Fidschi",
  },
  {
    abbr: "FI",
    chinese: "芬兰",
    code: "358",
    text: "Finland",
    spell: "fenlan",
    state: "欧洲",
    french: "Finlande",
    italian: "Finlandia",
    spanish: "Finlandia",
    japanese: "フィンランド",
    russian: "Финляндия",
    germen: "Finnland",
  },
  {
    abbr: "FR",
    chinese: "法国",
    code: "33",
    text: "France",
    spell: "faguo",
    state: "欧洲",
    french: "France",
    italian: "Francia",
    spanish: "Francia",
    japanese: "フランス",
    russian: "Франция",
    germen: "Frankreich",
  },
  {
    abbr: "GA",
    chinese: "加蓬",
    code: "241",
    text: "Gabon",
    spell: "jiapeng",
    state: "非洲",
    french: "Gabon",
    italian: "Gabon",
    spanish: "Gabón",
    japanese: "ガボン",
    russian: "Габон",
    germen: "Gabun",
  },
  {
    abbr: "GM",
    chinese: "冈比亚",
    code: "220",
    text: "Gambia",
    spell: "gangbiya",
    state: "非洲",
    french: "Gambie",
    italian: "Gambia",
    spanish: "Gambia",
    japanese: "ガンビア",
    russian: "Гамбия",
    germen: "Gambia",
  },
  {
    abbr: "GE",
    chinese: "格鲁吉亚",
    code: "995",
    text: "Georgia",
    spell: "gelujiya",
    french: "Géorgie",
    italian: "Georgia",
    spanish: "Georgia",
    japanese: "グルジア",
    russian: "Грузия",
    germen: "Georgien",
  },
  {
    abbr: "DE",
    chinese: "德国",
    code: "49",
    text: "Germany",
    spell: "deguo",
    state: "欧洲",
    french: "Allemagne",
    italian: "Germania",
    spanish: "Alemania",
    japanese: "ドイツ",
    russian: "Германия",
    germen: "Deutschland",
  },
  {
    abbr: "GH",
    chinese: "加纳",
    code: "233",
    text: "Ghana",
    spell: "jiana",
    state: "非洲",
    french: "Ghana",
    italian: "Ghana",
    spanish: "Ghana",
    japanese: "ガーナ",
    russian: "Гана",
    germen: "Ghana",
  },
  {
    abbr: "GR",
    chinese: "希腊",
    code: "30",
    text: "Greece",
    spell: "xila",
    state: "欧洲",
    french: "Grèce",
    italian: "Grecia",
    spanish: "Grecia",
    japanese: "ギリシャ",
    russian: "Греция",
    germen: "Griechenland",
  },
  {
    abbr: "GL",
    chinese: "格陵兰",
    code: "299",
    text: "Greenland",
    spell: "gelinglan",
    french: "Groenland",
    italian: "Groenlandia",
    spanish: "Groenlandia",
    japanese: "グリーンランド",
    russian: "Гренландия",
    germen: "Grönland",
  },
  {
    abbr: "GT",
    chinese: "危地马拉",
    code: "502",
    text: "Guatemala",
    spell: "weidimala",
    state: "北美洲",
    french: "Guatemala",
    italian: "Guatemala",
    spanish: "Guatemala",
    japanese: "グアテマラ",
    russian: "Гватемала",
    germen: "Guatemala",
  },
  {
    abbr: "GN",
    chinese: "几内亚",
    code: "224",
    text: "Guinea",
    spell: "jineiya",
    state: "非洲",
    french: "Guinée",
    italian: "Guinea",
    spanish: "Guinea",
    japanese: "ギニア",
    russian: "Гвинея",
    germen: "Guinea",
  },
  {
    abbr: "GY",
    chinese: "圭亚那",
    code: "592",
    text: "Guyana",
    spell: "guiyanei",
    state: "南美洲",
    french: "Guyane",
    italian: "Guyana",
    spanish: "Guyana",
    japanese: "ガイアナ",
    russian: "Гайана",
    germen: "Guyana",
  },
  {
    abbr: "HT",
    chinese: "海地",
    code: "509",
    text: "Haiti",
    spell: "haidi",
    state: "北美洲",
    french: "Haïti",
    italian: "Haiti",
    spanish: "Haiti",
    japanese: "ハイチ",
    russian: "Гаити",
    germen: "Haiti",
  },
  {
    abbr: "HN",
    chinese: "洪都拉斯",
    code: "504",
    text: "Honduras",
    spell: "hongdoulasi",
    state: "北美洲",
    french: "Honduras",
    italian: "Honduras",
    spanish: "Honduras",
    japanese: "ホンジュラス",
    russian: "Гондурас",
    germen: "Honduras",
  },
  {
    abbr: "HK",
    chinese: "中国香港特别行政区",
    code: "852",
    text: "Hongkong SAR China",
    spell: "zhongguoxianggangtebiexingzhengqu",
    state: "亚洲",
    french: "Hong Kong",
    italian: "Hong Kong",
    spanish: "Hong Kong",
    japanese: "香港",
    russian: "Гонконг SAR Китай",
    germen: "Hong Kong",
  },
  {
    abbr: "HU",
    chinese: "匈牙利",
    code: "36",
    text: "Hungary",
    spell: "xiongyali",
    state: "欧洲",
    french: "Hongrie",
    italian: "Ungheria",
    spanish: "Hungría",
    japanese: "ハンガリー",
    russian: "Венгрия",
    germen: "Ungarn",
  },
  {
    abbr: "IS",
    chinese: "冰岛",
    code: "354",
    text: "Iceland",
    spell: "bingdao",
    state: "欧洲",
    french: "Islande",
    italian: "Islanda",
    spanish: "Islandia",
    japanese: "アイスランド",
    russian: "Исландия",
    germen: "Island",
  },
  {
    abbr: "IN",
    chinese: "印度",
    code: "91",
    text: "India",
    spell: "yindu",
    state: "亚洲",
    french: "Inde",
    italian: "India",
    spanish: "India",
    japanese: "インド",
    russian: "Индия",
    germen: "Indien",
  },
  {
    abbr: "ID",
    chinese: "印度尼西亚",
    code: "62",
    text: "Indonesia",
    spell: "yindunixiya",
    state: "亚洲",
    french: "Indonésie",
    italian: "Indonesia",
    spanish: "Indonesia",
    japanese: "インドネシア",
    russian: "Индонезия",
    germen: "Indonesien",
  },
  {
    abbr: "IR",
    chinese: "伊朗",
    code: "98",
    text: "Iran",
    spell: "yilang",
    state: "亚洲",
    french: "Iran",
    italian: "Iran",
    spanish: "Iran",
    japanese: "イラン・イスラム共和国",
    russian: "Иран",
    germen: "Iran",
  },
  {
    abbr: "IQ",
    chinese: "伊拉克",
    code: "964",
    text: "Iraq",
    spell: "yilake",
    state: "亚洲",
    french: "Irak",
    italian: "Iraq",
    spanish: "Irak",
    japanese: "イラク",
    russian: "Ирак",
    germen: "Irak",
  },
  {
    abbr: "IE",
    chinese: "爱尔兰",
    code: "353",
    text: "Ireland",
    spell: "aierlan",
    state: "欧洲",
    french: "Irlande",
    italian: "Irlanda",
    spanish: "Irlanda",
    japanese: "アイルランド",
    russian: "Ирландия",
    germen: "Irland",
  },
  {
    abbr: "IM",
    chinese: "马恩岛",
    code: "44",
    text: "Isle of Man",
    spell: "maendao",
    french: "Royaume-Uni",
    italian: "Regno Unito",
    spanish: "Reino Unido",
    japanese: "イギリス",
    russian: "Остров Мэн",
    germen: "Vereinigtes Königreich",
  },
  {
    abbr: "IL",
    chinese: "以色列",
    code: "972",
    text: "Israel",
    spell: "yiselie",
    state: "亚洲",
    french: "Israël",
    italian: "Israele",
    spanish: "Israel",
    japanese: "イスラエル",
    russian: "Израиль",
    germen: "Israel",
  },
  {
    abbr: "IT",
    chinese: "意大利",
    code: "39",
    text: "Italy",
    spell: "yidali",
    state: "欧洲",
    french: "Italie",
    italian: "Italia",
    spanish: "Italia",
    japanese: "イタリア",
    russian: "Италия",
    germen: "Italien",
  },
  {
    abbr: "CI",
    chinese: "科特迪瓦",
    code: "225",
    text: "Ivory Coast",
    spell: "ketediwa",
    french: "Côte d\u0027Ivoire",
    italian: "Costa D\u0027Avorio",
    spanish: "Costa de Marfil",
    japanese: "コートジボワール",
    russian: "Кот-д'Ивуар",
    germen: "Elfenbeinküste",
  },
  {
    abbr: "JM",
    chinese: "牙买加",
    code: "1876",
    text: "Jamaica",
    spell: "yamaijia",
    state: "北美洲",
    french: "Jamaïque",
    italian: "Giamaica",
    spanish: "Jamaica",
    japanese: "ジャマイカ",
    russian: "Ямайка",
    germen: "Jamaika",
  },
  {
    abbr: "JP",
    chinese: "日本",
    code: "81",
    text: "Japan",
    spell: "riben",
    state: "亚洲",
    french: "Japon",
    italian: "Giappone",
    spanish: "Japón",
    japanese: "日本",
    russian: "Япония",
    germen: "Japan",
  },
  {
    abbr: "JO",
    chinese: "约旦",
    code: "962",
    text: "Jordan",
    spell: "yuedan",
    state: "亚洲",
    french: "Jordanie",
    italian: "Giordania",
    spanish: "Jordania",
    japanese: "ヨルダン",
    russian: "Иордания",
    germen: "Jordanien",
  },
  {
    abbr: "KZ",
    chinese: "哈萨克斯坦",
    code: "7",
    text: "Kazakstan",
    spell: "hasakesitan",
    state: "亚洲",
    french: "Russie",
    italian: "Russia",
    spanish: "Rusia",
    japanese: "ロシア連邦",
    russian: "Казахстан",
    germen: "Russland",
  },
  {
    abbr: "KE",
    chinese: "肯尼亚",
    code: "254",
    text: "Kenya",
    spell: "kenniya",
    state: "非洲",
    french: "Kenya",
    italian: "Kenya",
    spanish: "Kenia",
    japanese: "ケニア",
    russian: "Кения",
    germen: "Kenia",
  },
  {
    abbr: "KR",
    chinese: "韩国",
    code: "82",
    text: "Korea",
    spell: "hanguo",
    state: "亚洲",
    french: "Corée du Sud",
    italian: "Corea del Sud",
    spanish: "Corea del Sur",
    japanese: "大韓民国",
    russian: "Корея",
    germen: "Südkorea",
  },
  {
    abbr: "KW",
    chinese: "科威特",
    code: "965",
    text: "Kuwait",
    spell: "keweite",
    state: "亚洲",
    french: "Koweït",
    italian: "Kuwait",
    spanish: "Kuwait",
    japanese: "クウェート",
    russian: "Кувейт",
    germen: "Kuwait",
  },
  {
    abbr: "KG",
    chinese: "吉尔吉斯斯坦",
    code: "996",
    text: "Kyrgyzstan",
    spell: "jierjisisitan",
    french: "Kirghizistan",
    italian: "Kirghizistan",
    spanish: "Kirguizistán",
    japanese: "キルギス",
    russian: "Киргизия",
    germen: "Kirgisistan",
  },
  {
    abbr: "LA",
    chinese: "老挝",
    code: "856",
    text: "Laos",
    spell: "laowo",
    state: "亚洲",
    french: "Laos",
    italian: "Laos",
    spanish: "Laos",
    japanese: "ラオス人民民主共和国",
    russian: "Лаос",
    germen: "Laos",
  },
  {
    abbr: "LV",
    chinese: "拉脱维亚",
    code: "371",
    text: "Latvia",
    spell: "latuoweiya",
    state: "欧洲",
    french: "Lettonie",
    italian: "Lettonia",
    spanish: "Letonia",
    japanese: "ラトビア",
    russian: "Латвия",
    germen: "Lettland",
  },
  {
    abbr: "LB",
    chinese: "黎巴嫩",
    code: "961",
    text: "Lebanon",
    spell: "libanen",
    state: "亚洲",
    french: "Liban",
    italian: "Libano",
    spanish: "Líbano",
    japanese: "レバノン",
    russian: "Ливан",
    germen: "Libanon",
  },
  {
    abbr: "LS",
    chinese: "莱索托",
    code: "266",
    text: "Lesotho",
    spell: "laisuotuo",
    french: "Lesotho",
    italian: "Lesotho",
    spanish: "Lesotho",
    japanese: "レソト",
    russian: "Лесото",
    germen: "Lesotho",
  },
  {
    abbr: "LR",
    chinese: "利比里亚",
    code: "231",
    text: "Liberia",
    spell: "libiliya",
    french: "Liberia",
    italian: "Liberia",
    spanish: "Liberia",
    japanese: "リベリア",
    russian: "Либерия",
    germen: "Liberia",
  },
  {
    abbr: "LY",
    chinese: "利比亚",
    code: "218",
    text: "Libya",
    spell: "libiya",
    state: "非洲",
    french: "Libye",
    italian: "Libia",
    spanish: "Libia",
    japanese: "リビア",
    russian: "Ливия",
    germen: "Libyen",
  },
  {
    abbr: "LT",
    chinese: "立陶宛",
    code: "370",
    text: "Lithuania",
    spell: "litaowan",
    state: "欧洲",
    french: "Lituanie",
    italian: "Lituania",
    spanish: "Lituania",
    japanese: "リトアニア",
    russian: "Литва",
    germen: "Litauen",
  },
  {
    abbr: "LU",
    chinese: "卢森堡",
    code: "352",
    text: "Luxembourg",
    spell: "lusenbao",
    state: "亚洲",
    french: "Luxembourg",
    italian: "Lussemburgo",
    spanish: "Luxemburgo",
    japanese: "ルクセンブルク",
    russian: "Люксембург",
    germen: "Luxemburg",
  },
  {
    abbr: "MO",
    chinese: "中国澳门特别行政区",
    code: "853",
    text: "Macao SAR China",
    spell: "zhongguoaomentebiexingzhengqu",
    state: "亚洲",
    french: "Macao",
    italian: "Macao",
    spanish: "Macao",
    japanese: "マカオ",
    russian: "Макао SAR Китай",
    germen: "Macao",
  },
  {
    abbr: "MK",
    chinese: "马其顿",
    code: "389",
    text: "Macedonia",
    spell: "maqidun",
    french: "Macédoine",
    italian: "Macedonia",
    spanish: "Macedonia",
    japanese: "マケドニア旧ユーゴスラビア共和国",
    russian: "Македония",
    germen: "Mazedonien",
  },
  {
    abbr: "MG",
    chinese: "马达加斯加",
    code: "261",
    text: "Madagascar",
    spell: "madajiasijia",
    state: "非洲",
    french: "Madagascar",
    italian: "Madagascar",
    spanish: "Madagascar",
    japanese: "マダガスカル",
    russian: "Мадагаскар",
    germen: "Madagaskar",
  },
  {
    abbr: "MW",
    chinese: "马拉维",
    code: "265",
    text: "Malawi",
    spell: "malawei",
    french: "Malawi",
    italian: "Malawi",
    spanish: "Malawi",
    japanese: "マラウイ",
    russian: "Малави",
    germen: "Malawi",
  },
  {
    abbr: "MY",
    chinese: "马来西亚",
    code: "60",
    text: "Malaysia",
    spell: "malaixiya",
    state: "亚洲",
    french: "Malaisie",
    italian: "Malesia",
    spanish: "Malasia",
    japanese: "マレーシア",
    russian: "Малайзия",
    germen: "Malaysia",
  },
  {
    abbr: "MV",
    chinese: "马尔代夫",
    code: "960",
    text: "Maldives",
    spell: "maerdaifu",
    state: "亚洲",
    french: "Maldives",
    italian: "Maldive",
    spanish: "Maldivas",
    japanese: "モルディブ",
    russian: "Мальдивы",
    germen: "Malediven",
  },
  {
    abbr: "ML",
    chinese: "马里",
    code: "223",
    text: "Mali",
    spell: "mali",
    state: "非洲",
    french: "Mali",
    italian: "Mali",
    spanish: "Mali",
    japanese: "マリ",
    russian: "Мали",
    germen: "Mali",
  },
  {
    abbr: "MT",
    chinese: "马耳他",
    code: "356",
    text: "Malta",
    spell: "maerta",
    state: "欧洲",
    french: "Malte",
    italian: "Malta",
    spanish: "Malta",
    japanese: "マルタ",
    russian: "Мальта",
    germen: "Malta",
  },
  {
    abbr: "MR",
    chinese: "毛利塔尼亚",
    code: "222",
    text: "Mauritania",
    spell: "maolitaniya",
    state: "非洲",
    french: "Mauritanie",
    italian: "Mauritania",
    spanish: "Mauritania",
    japanese: "モーリタニア",
    russian: "Мавритания",
    germen: "Mauretanien",
  },
  {
    abbr: "MU",
    chinese: "毛里求斯",
    code: "230",
    text: "Mauritius",
    spell: "maoliqiusi",
    state: "非洲",
    french: "Île Maurice",
    italian: "Mauritius",
    spanish: "Mauricio",
    japanese: "モーリシャス",
    russian: "Маврикий",
    germen: "Mauritius",
  },
  {
    abbr: "MX",
    chinese: "墨西哥",
    code: "52",
    text: "Mexico",
    spell: "moxige",
    state: "北美洲",
    french: "Mexique",
    italian: "Messico",
    spanish: "México",
    japanese: "メキシコ",
    russian: "Мексика",
    germen: "Mexiko",
  },
  {
    abbr: "MD",
    chinese: "摩尔多瓦",
    code: "373",
    text: "Moldova",
    spell: "moerduowa",
    state: "欧洲",
    french: "Moldavie",
    italian: "Moldavia",
    spanish: "Moldavia",
    japanese: "モルドバ共和国",
    russian: "Молдавия",
    germen: "Moldawie",
  },
  {
    abbr: "MC",
    chinese: "摩纳哥",
    code: "377",
    text: "Monaco",
    spell: "monage",
    state: "欧洲",
    french: "Monaco",
    italian: "Principato di Monaco",
    spanish: "Mónaco",
    japanese: "モナコ",
    russian: "Монако",
    germen: "Monaco",
  },
  {
    abbr: "MN",
    chinese: "蒙古",
    code: "976",
    text: "Mongolia",
    spell: "menggu",
    state: "亚洲",
    french: "Mongolie",
    italian: "Mongolia",
    spanish: "Mongolia",
    japanese: "モンゴル",
    russian: "Монголия",
    germen: "Mongolei",
  },
  {
    abbr: "ME",
    chinese: "黑山共和国",
    code: "382",
    text: "Montenegro",
    spell: "heishangongheguo",
    french: "Monténégro",
    italian: "Montenegro",
    spanish: "Montenegro",
    japanese: "モンテネグロ",
    russian: "Черногория",
    germen: "Montenegro",
  },
  {
    abbr: "MA",
    chinese: "摩洛哥",
    code: "212",
    text: "Morocco",
    spell: "moluoge",
    state: "非洲",
    french: "Sahara Occidental",
    italian: "Sahara Occidentale",
    spanish: "Sahara Occidental",
    japanese: "西サハラ",
    russian: "Марокко",
    germen: "Westsahara",
  },
  {
    abbr: "MZ",
    chinese: "莫桑比克",
    code: "258",
    text: "Mozambique",
    spell: "mosangbike",
    state: "非洲",
    french: "Mozambique",
    italian: "Mozambico",
    spanish: "Mozambique",
    japanese: "モザンビーク",
    russian: "Мозамбик",
    germen: "Mosambik",
  },
  {
    abbr: "MM",
    chinese: "缅甸",
    code: "95",
    text: "Myanmar(Burma)",
    spell: "miandian",
    french: "Myanmar",
    italian: "Birmania",
    spanish: "Myanmar",
    japanese: "ミャンマー",
    russian: "Мьянма (Бирма)",
    germen: "Myanmar",
  },
  {
    abbr: "NA",
    chinese: "纳米比亚",
    code: "264",
    text: "Namibia",
    spell: "namibiya",
    state: "非洲",
    french: "Namibie",
    italian: "Namibia",
    spanish: "Namibia",
    japanese: "ナミビア",
    russian: "Намибия",
    germen: "Namibia",
  },
  {
    abbr: "NP",
    chinese: "尼泊尔",
    code: "977",
    text: "Nepal",
    spell: "niboer",
    state: "亚洲",
    french: "Népal",
    italian: "Nepal",
    spanish: "Nepal",
    japanese: "ネパール",
    russian: "Непал",
    germen: "Népal",
  },
  {
    abbr: "NL",
    chinese: "荷兰",
    code: "31",
    text: "Netherlands",
    spell: "helan",
    state: "欧洲",
    french: "Pays-Bas",
    italian: "Paesi Bassi",
    spanish: "Países Bajos",
    japanese: "オランダ",
    russian: "Нидерланды",
    germen: "Niederlande",
  },
  {
    abbr: "NZ",
    chinese: "新西兰",
    code: "64",
    text: "New Zealand",
    spell: "xinxilan",
    state: "大洋洲",
    french: "Îles Pitcairn",
    italian: "Isole Pitcairn",
    spanish: "Islas Pitcairn",
    japanese: "ピトケアン",
    russian: "Новая Зеландия",
    germen: "Pitcairn",
  },
  {
    abbr: "NI",
    chinese: "尼加拉瓜",
    code: "505",
    text: "Nicaragua",
    spell: "nijialagua",
    state: "北美洲",
    french: "Nicaragua",
    italian: "Nicaragua",
    spanish: "Nicaragua",
    japanese: "ニカラグア",
    russian: "Никарагуа",
    germen: "Nicaragua",
  },
  {
    abbr: "NE",
    chinese: "尼日尔",
    code: "227",
    text: "Niger",
    spell: "nirier",
    state: "非洲",
    french: "Niger",
    italian: "Niger",
    spanish: "Níger",
    japanese: "ニジェール",
    russian: "Нигер",
    germen: "Niger",
  },
  {
    abbr: "NG",
    chinese: "尼日利亚",
    code: "234",
    text: "Nigeria",
    spell: "niriliya",
    state: "非洲",
    french: "Nigéria",
    italian: "Nigeria",
    spanish: "Nigeria",
    japanese: "ナイジェリア",
    russian: "Нигерия",
    germen: "Nigeria",
  },
  {
    abbr: "KP",
    chinese: "朝鲜",
    code: "850",
    text: "North Korea",
    spell: "chaoxian",
    state: "亚洲",
    french: "Corée du Nord",
    italian: "Corea del Nord",
    spanish: "Corea del Norte",
    japanese: "朝鮮民主主義人民共和国",
    russian: "Северная Корея",
    germen: "Nordkorea",
  },
  {
    abbr: "NO",
    chinese: "挪威",
    code: "47",
    text: "Norway",
    spell: "nuowei",
    state: "欧洲",
    french: "Norvège",
    italian: "Norvegia",
    spanish: "Noruega",
    japanese: "ノルウェー",
    russian: "Норвегия",
    germen: "Norwegen",
  },
  {
    abbr: "OM",
    chinese: "阿曼",
    code: "968",
    text: "Oman",
    spell: "aman",
    state: "亚洲",
    french: "Oman",
    italian: "oman",
    spanish: "Omán",
    japanese: "オマーン",
    russian: "Оман",
    germen: "Oman",
  },
  {
    abbr: "PK",
    chinese: "巴基斯坦",
    code: "92",
    text: "Pakistan",
    spell: "bajisitan",
    state: "亚洲",
    french: "Pakistan",
    italian: "Pakistan",
    spanish: "Pakistán",
    japanese: "パキスタン",
    russian: "Пакистан",
    germen: "Pakistan",
  },
  {
    abbr: "PA",
    chinese: "巴拿马",
    code: "507",
    text: "Panama",
    spell: "banama",
    state: "北美洲",
    french: "Panama",
    italian: "Panama",
    spanish: "Panamá",
    japanese: "パナマ",
    russian: "Панама",
    germen: "Panama",
  },
  {
    abbr: "PY",
    chinese: "巴拉圭",
    code: "595",
    text: "Paraguay",
    spell: "balagui",
    state: "南美洲",
    french: "Paraguay",
    italian: "Paraguay",
    spanish: "Paraguay",
    japanese: "パラグアイ",
    russian: "Парагвай",
    germen: "Paraguay",
  },
  {
    abbr: "PE",
    chinese: "秘鲁",
    code: "51",
    text: "Peru",
    spell: "milu",
    state: "南美洲",
    french: "Pérou",
    italian: "Perù",
    spanish: "Perú",
    japanese: "ペルー",
    russian: "Перу",
    germen: "Peru",
  },
  {
    abbr: "PH",
    chinese: "菲律宾",
    code: "63",
    text: "Philippines",
    spell: "feilvbin",
    state: "亚洲",
    french: "Philippines",
    italian: "Filippine",
    spanish: "Filipinas",
    japanese: "フィリピン",
    russian: "Филиппины",
    germen: "Philippinen",
  },
  {
    abbr: "PL",
    chinese: "波兰",
    code: "48",
    text: "Poland",
    spell: "bolan",
    state: "欧洲",
    french: "Pologne",
    italian: "Polonia",
    spanish: "Polonia",
    japanese: "ポーランド",
    russian: "Польша",
    germen: "Polen",
  },
  {
    abbr: "PT",
    chinese: "葡萄牙",
    code: "351",
    text: "Portugal",
    spell: "putaoya",
    state: "欧洲",
    french: "Portugal",
    italian: "Portogallo",
    spanish: "Portugal",
    japanese: "ポルトガル",
    russian: "Португалия",
    germen: "Portugal",
  },
  {
    abbr: "PR",
    chinese: "波多黎各",
    code: "1787",
    text: "Puerto Rico",
    spell: "boduolige",
    french: "Porto Rico",
    italian: "Porto Rico",
    spanish: "Puerto Rico",
    japanese: "プエルトリコ",
    russian: "Пуэрто-Рико",
    germen: "Puerto Rico",
  },
  {
    abbr: "QA",
    chinese: "卡塔尔",
    code: "974",
    text: "Qatar",
    spell: "kataer",
    state: "亚洲",
    french: "Qatar",
    italian: "Qatar",
    spanish: "Catar",
    japanese: "カタール",
    russian: "Катар",
    germen: "Katar",
  },
  {
    abbr: "RE",
    chinese: "留尼旺",
    code: "262",
    text: "Reunion",
    spell: "liuniwang",
    french: "Réunion",
    italian: "Riunione",
    spanish: "Reunión",
    japanese: "レユニオン",
    russian: "воссоединение",
    germen: "Réunion",
  },
  {
    abbr: "RO",
    chinese: "罗马尼亚",
    code: "40",
    text: "Romania",
    spell: "luomaniya",
    state: "欧洲",
    french: "Roumanie",
    italian: "Romania",
    spanish: "Rumania",
    japanese: "ルーマニア",
    russian: "Румыния",
    germen: "Rumänien",
  },
  {
    abbr: "RU",
    chinese: "俄罗斯",
    code: "7",
    text: "Russia",
    spell: "eluosi",
    state: "欧洲",
    french: "Russie",
    italian: "Russia",
    spanish: "Rusia",
    japanese: "ロシア連邦",
    russian: "Россия",
    germen: "Russland",
  },
  {
    abbr: "RW",
    chinese: "卢旺达",
    code: "250",
    text: "Rwanda",
    spell: "luwangda",
    state: "非洲",
    french: "Rwanda",
    italian: "Ruanda",
    spanish: "Ruanda",
    japanese: "ルワンダ",
    russian: "Руанда",
    germen: "Ruanda",
  },
  {
    abbr: "SM",
    chinese: "圣马力诺",
    code: "378",
    text: "San Marino",
    spell: "shengmalinuo",
    state: "欧洲",
    french: "Saint-Marin",
    italian: "San Marino",
    spanish: "San Marino",
    japanese: "サンマリノ",
    russian: "Сан-Марино",
    germen: "San Marino",
  },
  {
    abbr: "SA",
    chinese: "沙特阿拉伯",
    code: "966",
    text: "Saudi Arabia",
    spell: "shatealabo",
    state: "亚洲",
    french: "Arabie Saoudite",
    italian: "Arabia Saudita",
    spanish: "Arabia Saudí",
    japanese: "サウジアラビア",
    russian: "Саудовская Аравия",
    germen: "Saudi-Arabien",
  },
  {
    abbr: "SN",
    chinese: "塞内加尔",
    code: "221",
    text: "Senegal",
    spell: "saineijiaer",
    state: "非洲",
    french: "Sénégal",
    italian: "Senegal",
    spanish: "Senegal",
    japanese: "セネガル",
    russian: "Сенегал",
    germen: "Senegal",
  },
  {
    abbr: "RS",
    chinese: "塞尔维亚",
    code: "381",
    text: "Serbia",
    spell: "saierweiya",
    french: "Serbie",
    italian: "Serbia",
    spanish: "Serbia",
    japanese: "セルビア",
    russian: "Сербия",
    germen: "Serbien",
  },
  {
    abbr: "SL",
    chinese: "塞拉利昂",
    code: "232",
    text: "Sierra Leone",
    spell: "sailaliang",
    french: "Sierra Leone",
    italian: "Sierra Leone",
    spanish: "Sierra Leone",
    japanese: "シエラレオネ",
    russian: "Сьерра-Леоне",
    germen: "Sierra Leone",
  },
  {
    abbr: "SG",
    chinese: "新加坡",
    code: "65",
    text: "Singapore",
    spell: "xinjiapo",
    state: "亚洲",
    french: "Singapour",
    italian: "Singapore",
    spanish: "Singapur",
    japanese: "シンガポール",
    russian: "Сингапур",
    germen: "Singapur",
  },
  {
    abbr: "SK",
    chinese: "斯洛伐克",
    code: "421",
    text: "Slovakia",
    spell: "siluofake",
    state: "欧洲",
    french: "Slovaquie",
    italian: "Slovacchia",
    spanish: "República Eslovaca",
    japanese: "スロバキア",
    russian: "Словакия",
    germen: "Slowakei",
  },
  {
    abbr: "SI",
    chinese: "斯洛文尼亚",
    code: "386",
    text: "Slovenia",
    spell: "siluowenniya",
    french: "Slovénie",
    italian: "Slovenia",
    spanish: "Eslovenia",
    japanese: "スロベニア",
    russian: "Словения",
    germen: "Slowenien",
  },
  {
    abbr: "SO",
    chinese: "索马里",
    code: "252",
    text: "Somalia",
    spell: "suomali",
    state: "非洲",
    french: "Somalie",
    italian: "Somalia",
    spanish: "Somalia",
    japanese: "ソマリア",
    russian: "Сомали",
    germen: "Somalia",
  },
  {
    abbr: "ZA",
    chinese: "南非",
    code: "27",
    text: "South Africa",
    spell: "nanfei",
    state: "非洲",
    french: "Afrique du Sud",
    italian: "Sud Africa",
    spanish: "República de Sudáfrica",
    japanese: "南アフリカ",
    russian: "Южно-Африканская Республика (ЮАР)",
    germen: "Republik Südafrika",
  },
  {
    abbr: "ES",
    chinese: "西班牙",
    code: "34",
    text: "Spain",
    spell: "xibanya",
    state: "欧洲",
    french: "Espagne",
    italian: "Spagna",
    spanish: "España",
    japanese: "スペイン",
    russian: "Испания",
    germen: "Spanien",
  },
  {
    abbr: "LK",
    chinese: "斯里兰卡",
    code: "94",
    text: "Sri Lanka",
    spell: "sililanka",
    french: "Sri Lanka",
    italian: "Sri Lanka",
    spanish: "Sri Lanka",
    japanese: "スリランカ",
    russian: "Шри-Ланка",
    germen: "Sri Lanka",
  },
  {
    abbr: "SD",
    chinese: "苏丹",
    code: "249",
    text: "Sudan",
    spell: "sudan",
    state: "非洲",
    french: "Soudan",
    italian: "Sudan",
    spanish: "Sudán",
    japanese: "スーダン",
    russian: "Судан",
    germen: "Sudan",
  },
  {
    abbr: "SR",
    chinese: "苏里南",
    code: "597",
    text: "Suriname",
    spell: "sulinan",
    french: "Surinam",
    italian: "Suriname",
    spanish: "Surinam",
    japanese: "スリナム",
    russian: "Суринам",
    germen: "Suriname",
  },
  {
    abbr: "SZ",
    chinese: "斯威士兰",
    code: "268",
    text: "Swaziland",
    spell: "siweishilan",
    french: "Swaziland",
    italian: "Swaziland",
    spanish: "Suazilandia",
    japanese: "スワジランド",
    russian: "Свазиленд",
    germen: "Swasiland",
  },
  {
    abbr: "SE",
    chinese: "瑞典",
    code: "46",
    text: "Sweden",
    spell: "ruidian",
    state: "欧洲",
    french: "Suède",
    italian: "Svezia",
    spanish: "Suecia",
    japanese: "スウェーデン",
    russian: "Швеция",
    germen: "Schweden",
  },
  {
    abbr: "CH",
    chinese: "瑞士",
    code: "41",
    text: "Switzerland",
    spell: "ruishi",
    state: "欧洲",
    french: "Suisse",
    italian: "Svizzera",
    spanish: "Suiza",
    japanese: "スイス",
    russian: "Швейцария",
    germen: "Schweiz",
  },
  {
    abbr: "SY",
    chinese: "叙利亚",
    code: "963",
    text: "Syria",
    spell: "xuliya",
    state: "亚洲",
    french: "Syrie",
    italian: "Siria",
    spanish: "Siria",
    japanese: "シリア・アラブ共和国",
    russian: "Сирия",
    germen: "Syrien",
  },
  {
    abbr: "TW",
    chinese: "中国台湾",
    code: "886",
    text: "Taiwan",
    spell: "zhongguotaiwan",
    state: "亚洲",
    french: "Taïwan",
    italian: "Taiwan",
    spanish: "Taiwán",
    japanese: "台湾（中華民国）",
    russian: "Тайвань",
    germen: "Taiwan",
  },
  {
    abbr: "TJ",
    chinese: "塔吉克斯坦",
    code: "992",
    text: "Tajikstan",
    spell: "tajikesitan",
    state: "亚洲",
    french: "Tadjikistan",
    italian: "Tagikistan",
    spanish: "Tayikistán",
    japanese: "タジキスタン",
    russian: "Таджикистан",
    germen: "Tadschikistan",
  },
  {
    abbr: "TZ",
    chinese: "坦桑尼亚",
    code: "255",
    text: "Tanzania",
    spell: "tansangniya",
    state: "非洲",
    french: "Tanzanie",
    italian: "Tanzania",
    spanish: "Tanzania",
    japanese: "タンザニア",
    russian: "Танзания",
    germen: "Tansania",
  },
  {
    abbr: "TH",
    chinese: "泰国",
    code: "66",
    text: "Thailand",
    spell: "taiguo",
    state: "亚洲",
    french: "Thaïlande",
    italian: "Tailandia",
    spanish: "Tailandia",
    japanese: "タイ",
    russian: "Таиланд",
    germen: "Thailand",
  },
  {
    abbr: "TG",
    chinese: "多哥",
    code: "228",
    text: "Togo",
    spell: "duoge",
    french: "Togo",
    italian: "Togo",
    spanish: "Togo",
    japanese: "トーゴ",
    russian: "Того",
    germen: "Togo",
  },
  {
    abbr: "TO",
    chinese: "汤加",
    code: "676",
    text: "Tonga",
    spell: "tangjia",
    state: "大洋洲",
    french: "Tonga",
    italian: "Tonga",
    spanish: "Tonga",
    japanese: "トンガ",
    russian: "Тонга",
    germen: "Tonga",
  },
  {
    abbr: "TT",
    chinese: "特立尼达和多巴哥",
    code: "1868",
    text: "Trinidad and Tobago",
    spell: "telinidaheduobage",
    french: "Trinité et Tobago",
    italian: "Trinidad e Tobago",
    spanish: "Trinidad y Tobago",
    japanese: "トリニダード・トバゴ",
    russian: "Тринидад и Тобаго",
    germen: "Trinidad und Tobago",
  },
  {
    abbr: "TN",
    chinese: "突尼斯",
    code: "216",
    text: "Tunisia",
    spell: "tunisi",
    state: "非洲",
    french: "Tunisie",
    italian: "Tunisia",
    spanish: "Túnez",
    japanese: "チュニジア",
    russian: "Тунис",
    germen: "Tunesien",
  },
  {
    abbr: "TR",
    chinese: "土耳其",
    code: "90",
    text: "Turkey",
    spell: "tuerqi",
    french: "Turquie",
    italian: "Turchia",
    spanish: "Turquía",
    japanese: "トルコ",
    russian: "Турция",
    germen: "Türkei",
  },
  {
    abbr: "TM",
    chinese: "土库曼斯坦",
    code: "993",
    text: "Turkmenistan",
    spell: "tukumansitan",
    state: "亚洲",
    french: "Turkménistan",
    italian: "Turkmenistan",
    spanish: "Turkmenistán",
    japanese: "トルクメニスタン",
    russian: "Туркменистан",
    germen: "Turkmenistan",
  },
  {
    abbr: "VI",
    chinese: "美属维尔京群岛",
    code: "1340",
    text: "U.S. Virgin Islands",
    spell: "meishuweierjingqundao",
    french: "Îles Vierges américaines",
    italian: "Isole Vergini americane",
    spanish: "Islas Vírgenes de EE.UU",
    japanese: "米領バージン諸島",
    russian: "Виргинские острова США",
    germen: "US Jungferninseln",
  },
  {
    abbr: "UG",
    chinese: "乌干达",
    code: "256",
    text: "Uganda",
    spell: "wuganda",
    state: "非洲",
    french: "Uganda",
    italian: "Uganda",
    spanish: "Uganda",
    japanese: "ウガンダ",
    russian: "Уганда",
    germen: "Uganda",
  },
  {
    abbr: "UA",
    chinese: "乌克兰",
    code: "380",
    text: "Ukraine",
    spell: "wukelan",
    state: "欧洲",
    french: "Ukraine",
    italian: "Ucraina",
    spanish: "Ucrania",
    japanese: "ウクライナ",
    russian: "Украина",
    germen: "Ukraine",
  },
  {
    abbr: "AE",
    chinese: "阿拉伯联合酋长国",
    code: "971",
    text: "United Arab Emirates",
    spell: "alabolianheqiuzhangguo",
    state: "亚洲",
    french: "Émirats arabes unis",
    italian: "Emirati Arabi Uniti",
    spanish: "Emiratos Árabes Unidos",
    japanese: "アラブ首長国連邦",
    russian: "Объединённые Арабские Эмираты (ОАЭ)",
    germen: "Vereinigte Arabische Emirate",
  },
  {
    abbr: "GB",
    chinese: "英国",
    code: "44",
    text: "United Kiongdom",
    spell: "yingguo",
    state: "欧洲",
    french: "Royaume-Uni",
    italian: "Regno Unito",
    spanish: "Reino Unido",
    japanese: "イギリス",
    russian: "Объединенное Королевство",
    germen: "Vereinigtes Königreich",
  },
  {
    abbr: "US",
    chinese: "美国",
    code: "1",
    text: "USA",
    spell: "meiguo",
    state: "北美洲",
    french: "États-Unis",
    italian: "Stati Uniti D\u0027America",
    spanish: "Estados Unidos",
    japanese: "アメリカ合衆国",
    russian: "Соединенные Штаты Америки",
    germen: "Vereinigte Staaten von Amerika",
  },
  {
    abbr: "UY",
    chinese: "乌拉圭",
    code: "598",
    text: "Uruguay",
    spell: "wulagui",
    state: "南美洲",
    french: "Uruguay",
    italian: "Uruguay",
    spanish: "Uruguay",
    japanese: "ウルグアイ",
    russian: "Уругвай",
    germen: "Uruguay",
  },
  {
    abbr: "UZ",
    chinese: "乌兹别克斯坦",
    code: "998",
    text: "Uzbekistan",
    spell: "wuzibiekesitan",
    state: "亚洲",
    french: "Ouzbékistan",
    italian: "Uzbekistan",
    spanish: "Uzbekistán",
    japanese: "ウズベキスタン",
    russian: "Узбекистан",
    germen: "Usbekistan",
  },
  {
    abbr: "VA",
    chinese: "梵蒂冈城",
    code: "379",
    text: "Vatican City",
    spell: "fandigangcheng",
    state: "欧洲",
    french: "voir Saint",
    italian: "Santa Sede",
    spanish: "Santa Sede",
    japanese: "聖座",
    russian: "Ватикан",
    germen: "Heiliger Stuhl",
  },
  {
    abbr: "VE",
    chinese: "委内瑞拉",
    code: "58",
    text: "Venezuela",
    spell: "weineiruila",
    state: "北美洲",
    french: "Venezuela",
    italian: "Venezuela",
    spanish: "Venezuela",
    japanese: "ベネズエラ・ボリバル共和国",
    russian: "Венесуэла",
    germen: "Venezuela",
  },
  {
    abbr: "VN",
    chinese: "越南",
    code: "84",
    text: "Vietnam",
    spell: "yuenan",
    state: "亚洲",
    french: "Viêt Nam",
    italian: "Vietnam",
    spanish: "Vietnam",
    japanese: "ベトナム",
    russian: "Вьетнам",
    germen: "Vietnam",
  },
  {
    abbr: "YE",
    chinese: "也门",
    code: "967",
    text: "Yemen",
    spell: "yemen",
    state: "亚洲",
    french: "Yémen",
    italian: "Yemen",
    spanish: "Yemen",
    japanese: "イエメン",
    russian: "Йемен",
    germen: "Jemen",
  },
  {
    abbr: "YU",
    chinese: "南斯拉夫",
    code: "381",
    text: "Yugoslavia",
    spell: "nansilafu",
    state: "欧洲",
    french: "Serbie",
    italian: "Serbia",
    spanish: "Serbia",
    japanese: "セルビア",
    russian: "Югославия",
    germen: "Serbien",
  },
  {
    abbr: "ZR",
    chinese: "扎伊尔",
    code: "243",
    text: "Zaire",
    spell: "zhayier",
    state: "非洲",
    french: "Congo (Rép. dém.)",
    italian: "Congo (Rep. Dem.)",
    spanish: "Congo (Rep. Dem.)",
    japanese: "コンゴ民主共和国",
    russian: "Заир",
    germen: "Kongo (Dem. Rep.)",
  },
  {
    abbr: "ZM",
    chinese: "赞比亚",
    code: "260",
    text: "Zambia",
    spell: "zanbiya",
    state: "非洲",
    french: "Zambie",
    italian: "Zambia",
    spanish: "Zambia",
    japanese: "ザンビア",
    russian: "Замбия",
    germen: "Sambia",
  },
  {
    abbr: "ZW",
    chinese: "津巴布韦",
    code: "263",
    text: "Zimbabwe",
    spell: "jinbabuwei",
    state: "非洲",
    french: "Zimbabwe",
    italian: "Zimbabwe",
    spanish: "Zimbabue",
    japanese: "ジンバブエ",
    russian: "Зимбабве",
    germen: "Simbabwe",
  },
];
export default counties;