import React, { useState, useCallback, useEffect } from "react";

import { PADOEXTENSIONDOWNLOADURL } from "@/config/constants";
import eventLinea from "@img/events/eventLinea.svg";
import eventBAS from "@img/events/eventBAS.svg";
import eventEthSign from "@img/events/eventEthSign.svg";

import "./index.scss";
const rewardList = [
  {
    id: "LINEA_DEFI_VOYAGE",
    img: eventLinea,
    link: PADOEXTENSIONDOWNLOADURL,
    period: "6 Dec 2023 ~ Till Now",
    title: "Linea-Verax Voyage",
    desc: "Verax is a shared, public attestation registry deployable to EVM chains. It serves as a repository where dApps can store attestations that are specific statements made by an issuer.",
  },
  {
    id: "SIGNX_X_PROGRAM",
    img: eventEthSign,
    link: PADOEXTENSIONDOWNLOADURL,
    period: "24 Jan 2024 ~ 14 Feb 2024",
    title: "SignX Program",
    desc: "Sign Protocol is an omni-chain attestation protocol, enabling users to freely attest and verify any information on-chain.",
  },

  {
    id: "BAS_EVENT_PROOF_OF_HUMANITY",
    img: eventBAS,
    link: PADOEXTENSIONDOWNLOADURL,
    period: "24 Jan 2024 ~ 14 Feb 2024",
    title: "BSC Attestation Alliance",
    desc: "The BNB Attestation Service (BAS) is an infrastructure built on the BNB ecosystem for generating attestation to verify information. BAS assists users in on-chain or off-chain verification, allowing them to assert ownership of attestation by storing them in Greenfield.",
  },
];
const List = () => {
  const onClickA = (e, i) => {
    var specialEl = document.querySelector("#pado-extension-inject-el");
    if (specialEl) {
      e.preventDefault();
      window.postMessage(
        {
          target: "padoExtension",
          name: "event",
          params: {
            eventName: i.id,
            methodName: "createTab",
            path: "home.html#/events",
          },
        },
        "*"
      );
    } else {
      window.open(PADOEXTENSIONDOWNLOADURL);
    }
  };

  return (
    <section className="eventsListWrapper contentWidth">
      <div className="eventsList">
        <h2>Tasks</h2>
        <ul className="rewards">
          {rewardList.map((i) => {
            return (
              <li className="reward" key={i.id}>
                <a
                  href={i.link}
                  target="_blank"
                  rel="noreferrer"
                  onClick={(e) => {
                    onClickA(e, i);
                  }}
                >
                  <div className="left">
                    <div className="top">
                      {/* <div className="period">{i.period}</div> */}
                      <div className="title">{i.title}</div>
                      <div className="desc">{i.desc}</div>
                    </div>
                    <div className="bottom">
                      <div className="btnWrapper">
                        <span>Start</span>
                        <div className="arrowWrapper">
                          <i className="iconfont icon-iconArrow"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <img className="right" src={i.img} alt="" />
                </a>
              </li>
            );
          })}
        </ul>
      </div>
    </section>
  );
};
export default List;
