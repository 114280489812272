import React, { useState, useEffect } from "react";
import { PADOEXTENSIONDOWNLOADURL } from "@/config/constants";
import PButton from "@com/PButton";
import "./index.scss";
import techAchi from "@img/BASEvent/techAchi.svg";
import iconArrowRight from "@img/home/iconArrowRight.svg";
import logoWhite from "@img/BASEvent/logoWhite.svg";
import logoCombine from "@img/BASEvent/iconCombine.svg";
import logoBAS from "@img/BASEvent/iconBAS.svg";
import { useNavigate } from "react-router-dom";
import Friends from "@/components/Layout/Friends";
const dataList = [
  {
    id: "2",
    keyword: "less than",
    num: "+100xp",
    desc: "Binance Account",
  },
  {
    id: "6",
    keyword: "less than",
    num: "+80xp",
    desc: "TikTok Account",
  },
  {
    id: "100",
    keyword: "less than",
    num: "+60xp",
    desc: "Google Account",
  },
  {
    id: "3",
    keyword: "Proof of Account Ownership",
    num: "+60xp",
    desc: "Twitter Account",
  },
];
const TechAchievements = () => {
  const navigate = useNavigate();
  const onClickMore = () => {
    var specialEl = document.querySelector("#pado-extension-inject-el");
    if (specialEl) {
    //   // window.postMessage(
    //   //   {
    //   //     target: "padoExtension",
    //   //     name: "event",
    //   //     params: {
    //   //       eventName: "BAS_EVENT_PROOF_OF_HUMANITY",
    //   //       methodName: "createTab",
    //   //       path: "home.html#/events",
    //   //     },
    //   //   },
    //   //   "*"
    //   // );
    } else {
      window.open(PADOEXTENSIONDOWNLOADURL);
    }
  };
  const handleClickLogo = () => {
    navigate("/");
  };
  useEffect(() => {
    const fn = function () {
      // Check if the page is hidden
      if (document.hidden) {
        console.log("User opened another tab");
      } else {
        // var specialEl = document.querySelector("#pado-extension-inject-el");
        // if (!specialEl) {
        console.log("pado content unjected,reload...");
        window.location.reload();
        // }
      }
    };
    document.addEventListener("visibilitychange", fn);
    return () => {
      document.removeEventListener("visibilitychange", fn);
    };
  }, []);
  return (
    <section className="sectionTechAchievementsBAS">
      <div className="contentWidth">
        <div className="left">
          <div className="left-top">
            <div className="imgWrapper">
              <img
                src={logoWhite}
                alt=""
                onClick={handleClickLogo}
                className="iconPado"
              />
              <img src={logoCombine} alt="" className="iconCombine" />
              <img src={logoBAS} alt="" className="iconBAS" />
            </div>

            <h2>
              BAS <br />
              Attestation Alliance
            </h2>

            <p className="desc">
              PADO will help bring traditional data sources to the BNB
              ecosystem, and cater to the diversified data needs of BNB
              ecosystem Dapps.
            </p>
          </div>

          <img src={techAchi} alt="" />
        </div>
        <div className="right">
          <p>
            The BNB Attestation Service (BAS) is an infrastructure built on the
            BNB ecosystem for generating attestation to verify information. BAS
            assists users in on-chain or off-chain verification, allowing them
            to assert ownership of attestation by storing them in Greenfield.
          </p>
          <div className="right-center">
            <div className="titleWrapper">
              <div className="label">about the tasks</div>
              <div className="desc">
                Please accomplish the following attestations throw the PADO
                extension and earn the points.
              </div>
            </div>
            <ul className="datas">
              {dataList.map((i) => {
                return (
                  <li className="data" key={i.id}>
                    <div className="keyword">Proof of Account Ownership</div>
                    <div className="detail">
                      <div className="desc">{i.desc}</div>
                      <div className="num">{i.num}</div>
                    </div>
                  </li>
                );
              })}
            </ul>
            <div className="cost">
              <div className="ct">cost</div>
              <div className="cn">
                <div className="label">Gas fee only</div>
                <div className="desc">
                  <span>0.003BNB service fee </span>&nbsp;covered by PADO
                </div>
              </div>
            </div>
          </div>
          <PButton
            text="Join Now"
            onClick={onClickMore}
            suffix={iconArrowRight}
            id="BAS_EVENT_PROOF_OF_HUMANITY_entrance"
          />
        </div>
        <Friends />
      </div>
    </section>
  );
};

export default TechAchievements;
