import React, { memo, useState } from "react";
import { PADOEXTENSIONDOWNLOADURL } from "@/config/constants";
import "./index.scss";
import PButton from "@/components/PButton";
import iconArrowRightWhite from "@/assets/img/home/iconArrowRightWhite.svg";
import illusraton from "@/assets/img/events/illustration.svg";

const SectionBanner = memo(() => {
  const onClickStart = () => {
    var specialEl = document.querySelector("#pado-extension-inject-el");
    if (specialEl) {
      window.postMessage(
        {
          target: "padoExtension",
          name: "event",
          params: {
            eventName: "",
            methodName: "createTab",
            path: "home.html#/events",
          },
        },
        "*"
      );
    } else {
      window.open(PADOEXTENSIONDOWNLOADURL);
    }
  };
  return (
    <section className="eventsBanner">
      <div className="sectionContent contentWidth">
        <img src={illusraton} alt="" className="illusraton" />
        <div className="left">
          <div className="title">
            PADO <br />
            Attestation Campaign
          </div>
          <PButton
            text="Join Now"
            onClick={onClickStart}
            suffix={iconArrowRightWhite}
          />
        </div>
      </div>
    </section>
  );
});

export default SectionBanner;
