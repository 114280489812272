import React, { useState } from "react";
import { PADODISCORDURL } from "@/config/constants";
import "./index.scss";
import iconFriendGithub from "@img/layout/iconFriendGithub.svg";
import iconFriendDiscord from "@img/layout/iconFriendDiscord.svg";
import iconFriendMedium from "@img/layout/iconFriendMedium.svg";
import iconFriendTwitter from "@img/layout/iconFriendTwitter.svg";
import iconFriendTelegram from "@img/layout/iconFriendTelegram.svg";
const friendList = [
  {
    icon: iconFriendTwitter,
    link: "https://twitter.com/padolabs",
    name: "Twitter",
  },
  {
    icon: iconFriendDiscord,
    link: PADODISCORDURL,
    name: "Discord",
  },
  {
    icon: iconFriendGithub,
    link: "https://github.com/PADO-labs",
    name: "Github",
  },
  {
    icon: iconFriendTelegram,
    link: "https://t.me/zkpadolabs",
    name: "Telegram",
  },
  {
    icon: iconFriendMedium,
    link: "https://medium.com/@padolabs",
    name: "Medium",
  },
];
const PageFooter = () => {
  return (
    <ul className="friends">
      {friendList.map((i) => {
        return (
          <li key={i.name}>
            <a href={i.link} target="_blank" rel="noreferrer">
              <img src={i.icon} alt="" />
            </a>
          </li>
        );
      })}
    </ul>
  );
};
export default PageFooter;
