import React from "react";
import iconOKX from "@/assets/img/connectWallet/iconOKX.svg";
import padoColorful from "@/assets/img/connectWallet/padoColorful.svg";
import "./index.scss";
const connectWallet = ({ children }) => {
  return (
    <div className="connectWalletPage">
      <div className="cardWrapper">
        <div className="cardBody">
          <div className="title">
            <h1>Connect your wallet</h1>
            <h2>
              Authorize through your web3 wallet address to PADO extension
            </h2>
          </div>
          <ul className="walletList">
            <li className="wallet">
              <img className="icon" src={iconOKX} alt=""></img>
              <div className="name">OKX Wallet</div>
            </li>
          </ul>
        </div>

        <footer>
          <img src={padoColorful} alt="" />
        </footer>
      </div>
    </div>
  );
};
export default connectWallet;
