export const PADODOCURL = "https://docs.padolabs.org/";
export const PADODOCURLHOWTOWORK = "https://docs.padolabs.org/welcome-to-pado/understanding-pado-protocol";
export const PADODOCURLPRIVACY = "https://docs.padolabs.org/Privacy-Policy";
export const PADOEXTENSIONDOWNLOADURL =
  "https://chrome.google.com/webstore/detail/pado/oeiomhmbaapihbilkfkhmlajkeegnjhe";
// export const LINEADEFIVOYAGEURL =
//   "chrome-extension://oeiomhmbaapihbilkfkhmlajkeegnjhe/home.html#/cred?fromEvents=LINEA_DEFI_VOYAGE";
export const LINEADEFIVOYAGEURL =
  "https://chrome.google.com/webstore/detail/pado/oeiomhmbaapihbilkfkhmlajkeegnjhe";
export const INTRACTURL =
  "https://www.intract.io/linea/quest/65703283527c05c643126f4f";
export const PADOVERIFYURL =
  "https://docs.padolabs.org/technical-overview/understand-pado-network";
export const PADODISCORDURL = "https://discord.gg/pdrNxRrApX";