import React, { useState, useCallback } from "react";
import iconBNBChain from "@img/about/iconBNBChain.svg";
import iconZKDayParis from "@img/about/iconZKDayParis.svg";
import iconETHFoundation from "@img/about/iconETHFoundation.svg";
import iconConsensys from "@img/about/iconConsensys.svg";
import iconArrowRight from "@img/home/iconArrowRight.svg";
import PTag from "@com/PTag";
import "./index.scss";
const rewardList = [
  {
    id: "0",
    img: iconConsensys,
    link: "https://github.com/privacy-scaling-explorations/",
    keyword: "Ethereum Foundation PSE",
    title: "Granted by ethereum foundation & pSE",
    desc: "The Ethereum Foundation (EF) is a non-profit organization that supports the Ethereum ecosystem. The EF Privacy & Scaling Explorations focuses on bridging the gap between advanced research in Zero-Knowledge Proofs (ZKP) and application development on Ethereum. PADO has been granted funding to develop the first open-source Rust IZK library for Ethereum community.",
  },
  {
    id: "1",
    img: iconConsensys,
    link: "https://consensys.io/blog/introducing-the-first-cohort-of-the-consensys-fellowship-program-for-web3",
    keyword: "Consensys Fellowship Program",
    title: "Accepted by the first cohort of consensys fellowship program",
    desc: "The Consensys Fellowship Program is designed to support innovative startups pushing the boundaries of web3, leveraging MetaMask SDK, MetaMask Snaps, and Linea. From DAO governance to smart contract integration and payment protocols, this diverse cohort covers a wide range of much-needed solutions.",
  },
  {
    id: "2",
    img: iconConsensys,
    link: "https://xcelerator.medium.com/bbx-2024-cohort-teams-announcement-73525d4ad170",
    keyword: "Berkeley Blockchain Xcelerator",
    title: "Selected by cohort 7 of Berkeley blockchain xcelerator",
    desc: "With 10 teams from around the world and 3 teams with Berkeley ties, this cohort is working at the intersection of AI and decentralization, innovating in the areas of zero-knowledge, data management and distributed computing, as well as entertainment, reward programs, remittances and sustainability.",
  },
  {
    id: "3",
    img: iconConsensys,
    link: "https://twitter.com/0xPolygon/status/1754870577758605458",
    keyword: "Polygon Village",
    title: "Selected by Polygon Village startup support program",
    desc: "Polygon Village Startup Support is a tailored support program for select founders offering immersive guidance, strategic support, 1:1 mentorship and access to an elite network.",
  },
];
const OurRewards = () => {
  const [activeReward, setActiveReward] = useState();
  const onClickReward = (i) => {
    if (activeReward === i.id) {
      setActiveReward(undefined);
    } else {
      setActiveReward(i.id);
    }
  };
  const liClassNameFn = useCallback(
    (i) => {
      let activeCL = "reward";
      if (activeReward === i.id) {
        activeCL += " active";
      }
      return activeCL;
    },
    [activeReward]
  );
  return (
    <section className="ourRewards contentWidth">
      <h2>Our Highlights</h2>
      <ul className={activeReward ? "rewards active" : "rewards"}>
        {rewardList.map((i) => {
          return (
            <li
              className={liClassNameFn(i)}
              key={i.id}
              onClick={() => {
                onClickReward(i);
              }}
            >
              <img className="aboutIcon" src={i.img} alt="" />
              <div className="descInfo">
                <PTag text={i.keyword} />
                <h5>{i.title}</h5>
                <div className="desc">
                  <p>{i.desc}</p>
                  <a
                    className="more"
                    href={i.link}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span>Learn More</span>
                    <div className="iconArrowRightWrapper"></div>
                    {/* <img
                      className="iconArrowRight"
                      src={iconArrowRight}
                      alt=""
                    /> */}
                  </a>
                </div>
              </div>
            </li>
          );
        })}
      </ul>
    </section>
  );
};
export default OurRewards;
