import React, { useEffect } from "react";
import Banner from "@com/Events/Banner";
import List from "@com/Events/List";

const Events = ({ children }) => {
  useEffect(() => {
    const fn = function () {
      // Check if the page is hidden
      if (document.hidden) {
        console.log("User opened another tab");
      } else {
        // var specialEl = document.querySelector("#pado-extension-inject-el");
        // if (!specialEl) {
        console.log("pado content unjected,reload...");
        window.location.reload();
        // }
      }
    };
    document.addEventListener("visibilitychange", fn);
    return () => {
      document.removeEventListener("visibilitychange", fn);
    };
  }, []);
  return (
    <div className="pageEvent">
      <Banner />
      <List />
    </div>
  );
};
export default Events;
